import FileMetaDTO from "@/dto/files/FileMetaDTO";
import {SSNType} from "@/constants/PersonConstants";

export default class SaveSSNVerificationPayload {
    personId: number | null = null;
    file: FileMetaDTO | null = null;
    verificationDate: Date | null = null;

    constructor(init?: Partial<SaveSSNVerificationPayload>) {
        Object.assign(this, init);
    }
}